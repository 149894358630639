<template>
  <a id="textree-mobile-more" href="#" :style="{background: background}" @click="trigger">
    <img :src="arrow" alt="">
  </a>
</template>

<script>
import store from '@/store'

export default {
  computed: {
    arrow: function () {
      if (store.getters.moreHasNextPage()) {
        if (store.state.mobileMore.fill) {
          return '/assets/icons/mobile-arrow-more-white.png'
        } else {
          return '/assets/icons/mobile-arrow-more-blue.png'
        }
      }
      if (store.state.mobileMore.fill) {
        return '/assets/icons/mobile-arrow-back-white.png'
      } else {
        return '/assets/icons/mobile-arrow-back-blue.png'
      }
    },
    background: function () {
      if (store.state.mobileMore.fill) {
        return '#008ED6'
      } else {
        return '#ffffff'
      }
    }
  },
  methods: {
    trigger: function () {
      if (store.getters.moreHasNextPage()) {
        store.commit('SET_MORE_NEXT_PAGE')
      } else {
        store.commit('SET_MORE_CURRENT_INDEX', 0)
      }
    }
  }
}
</script>

<style scoped>
#textree-mobile-more {
  display: none;
}

@media(max-width: 768px) {
  #textree-mobile-more {
    position: fixed;
    bottom: 0;
    display: block;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #textree-mobile-more img {
    width: 36px;
    height: 36px;
  }
}
</style>
